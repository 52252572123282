"use strict";

function getuigenisSliderCheck() {
	if ($(window).width() <= 576) {
		$(".getuigenissen-slider").slick({});
	}
}

function urlParam(name){
	var results = new RegExp('[\?&]' + name + '=([^&#]*)').exec(window.location.href);
	try{
		return results[1];
	}catch(err){
		return false;
	}
}

//check if mobile menu is gone
function removeMobileMenu(){
	if($(".off-canvas-nav").hasClass('active')){
		$(".off-canvas-nav").removeClass('active');
		$(".fullscreen-overlay").removeClass('active');
		$("body").removeClass('no-scrolling');
		$("html").removeClass('no-scrolling');
		// backToTop();
	}
}

function scrollToGet(){
	var scrollTarget = urlParam('s');
	$([document.documentElement, document.body]).animate({
		scrollTop: $("#" + scrollTarget).offset().top
	}, 1250);
	removeMobileMenu();
}

//show/hide back to top
var scrollTrigger = 100,
backToTop = function () {
	var scrollTop = $(window).scrollTop();
	if (scrollTop > scrollTrigger) {
		$('#backToTop').addClass('show');
	} else {
		$('#backToTop').removeClass('show');
	}
};



$(document).ready(function () {
	/* =====  MOBILE MENU TOGGLE  ====== */
	$(".offCanvasToggler").click(function (event) {
		event.preventDefault();
		$(".off-canvas-nav").toggleClass("active");
		$(".fullscreen-overlay").toggleClass("active");
		$("html, body").toggleClass("no-scrolling");
	});

	$(".images-slider").slick({
		arrows: false,
		dots: false,
		autoplay: true,
		autoplaySpeed: 5000,
	});

	$(".teamlid-mobile").click(function (event) {
		event.preventDefault();
		$(this).find(".down").slideToggle();
	});

	/* =====  SCROLL TO TOP  ====== */
	backToTop();
	$(window).on('scroll', function () {
		backToTop();
	});
	$('#backToTop').on('click', function (e) {
		e.preventDefault();
		$('html,body').animate({
			scrollTop: 0
		}, 800);
	});
	
	getuigenisSliderCheck();

	if(urlParam('s') !== false){
		scrollToGet();
	}

	$('.modal').each(function(i){
		$(this).modal('show');
	});
});

$(window).resize(function (event) {
	getuigenisSliderCheck();
});

$("a[data-scrollto]").click(function (event) {
	event.preventDefault();
	var scrollTarget = event.currentTarget.dataset.scrollto;

	$([document.documentElement, document.body]).animate({
		scrollTop: $("#" + scrollTarget).offset().top
	}, 1250);

	removeMobileMenu();
});
